<template>
	<div v-if="showComponent">
		<h3 class="text-xl font-bold uppercase">
			{{ listingData.name }} Reviews
		</h3>
		<router-link
			:to="`${listingData.url}/reviews`"
			class="py-2 font-bold text-green-500 uppercase"
		>
			View All
		</router-link>
		<BusinessIndexReviewCard
			v-for="(review, index) in aboutReviewsData.reviews"
			:key="review.id"
			:review-content="review"
			:class="{ 'border-b': index !== aboutReviewsData.reviews.length - 1 }"
		/>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import { ListingAboutReviewsFragment, ListingGeneralDetailsFragment } from '@/gql/fragments/listing.gql'

export default {
	components: {
		BusinessIndexReviewCard: () => import('@/components/business/BusinessIndexReviewCard.vue')
	},
	computed: {
		...mapGetters('business', [ 'businessId' ]),
		showComponent() {
			return this.aboutReviewsData?.reviews?.length && this.listingData?.url && this.listingData.name
		},
		aboutReviewsData() {
			return this.$apollo.provider.defaultClient.readFragment({
				id: `Listing:${this.businessId}`,
				fragment: ListingAboutReviewsFragment
			})
		},
		listingData() {
			return this.$apollo.provider.defaultClient.readFragment({
				fragment: ListingGeneralDetailsFragment,
				id: `Listing:${this.businessId}`
			})
		}
	}
}
</script>
